import React from 'react'

import Layout from '../components/layout/rootLayout.js'
import PurchasePage from '../components/purchase/purchasepage'

const Default = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <PurchasePage />
  </Layout>
)

export default Default;
