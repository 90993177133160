import styled, { css } from 'styled-components'
import { device, core } from '@src/style'
import * as ButtonsStyle from '@components/buttons/style'

export const FullscreenWrapper = styled.div`
  width: 100%;
`
export const Content = styled.div`
  display: flex;
  margin: 0 auto;
  ${props => props.theme.blogWidthLimiter}
`

export const VerificationBody = styled.div`
  display: flex;
  flex-direction: column;
`

export const H2 = styled(core.H2)`
  padding-top: 3.5rem;
`
export const H3 = styled(core.H3)`
  padding-top: 2rem;
`
export const PM = styled(core.PM)``
export const PS = styled(core.PS)``

export const BlueButton = styled(ButtonsStyle.BlueButton)`
  margin: 0rem 0px 1rem 0px;
`
export const VerifyBlueButton = styled(ButtonsStyle.BlueButton)`
  margin: 3rem 0px;
`