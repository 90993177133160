import React, { Fragment, useState, useEffect } from 'react'

import { FormattedMessage, injectIntl } from 'react-intl'
import { getFirebase } from '../firebase.js'
import API from '@src/common/api.js'
import Nav from '@components/nav/nav'
import PurchaseError from './purchaseError'
import Loading from './loading'
import Verification from './verification'
import PurchaseState from './purchaseState'
import { FullscreenWrapper, Content } from './styles'
import PayPal from '@src/common/paypal'
import config from '@src/common/config'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import Spinner from 'react-spinkit'
import { Link, localizedUrl } from '@src/components/link'
import Linker from '@src/common/linker'
import Eula from './eula/eula'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { EulaModalContext } from './eula/context.js'

export default injectIntl(({ intl: { locale } }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    purchaseStateReady: false
  })
  const [authModule, setAuthModule] = useState(undefined)
  const [auth, setAuth] = useState(undefined)
  const [error, setError] = useState(undefined)
  const [authSetup, setAuthSetup] = useState(false)
  const [processingPurchase, setProcessingPurchase] = useState(false)

  const [isEulaOpen, setIsEulaOpen] = useState(false)

  const componentDidMount = () => {
    const lazyApp = import('firebase/app')
    const lazyAuth = import('firebase/auth')
    Promise.all([lazyApp, lazyAuth]).then(([_firebase, auth]) => {
      const firebase = getFirebase(_firebase)
      setAuthModule(auth)
      setAuth(auth.getAuth())
    })
  }

  const onAuthStateChanged = user => {
    let isStripe = API.getParameterByName('paymentType') == 'stripe'
    let isCancelPage = API.getParameterByName('cancel')
    if (isCancelPage) {
      onPurchaseCancel()
    }

    let isSuccessPage = API.getParameterByName('success')
    if (isSuccessPage) {
      setProcessingPurchase(true)
      if (isStripe) {
        onPurchaseComplete(isStripe)
        return
      }
    } else {
      if (user) {
        updatePurchaseState()
      }
    }
  }

  const loadingCallback = ({ loading }) => {
    setLoading(loading)
  }

  const updatePurchaseState = () => {
    const preLicenseCode = API.getParameterByName('preLicenseCode')
    API.hasPurchase(auth.currentUser, loadingCallback).then(
      ({ hasPurchase, code, v3Codes, v3Active, serial, activations }) => {
        if (hasPurchase) {
          setData({
            purchaseStateReady: true,
            hasPurchase: hasPurchase,
            code: code,
            v3Codes: v3Codes,
            v3Active: v3Active,
            serial: serial,
            activations: activations,
            preLicenseActivationFailed: false,
            preLicenseActivationFinished: true
          })
        } else {
          if (
            preLicenseCode &&
            !data.preLicenseActivationFailed &&
            !data.preLicenseActivationFinished
          ) {
            // we need to activate user with pre-License code. Yes, it's a hole =(
            // well, not really, because preLicenseCode still needs to be valid and present on backend
            API.activatePreLicenseCode(
              preLicenseCode,
              locale,
              auth.currentUser,
              loadingCallback
            )
              .then(response => {
                trackCustomEvent({
                  category: 'ecommerce',
                  action: 'activate_partner'
                })

                updatePurchaseState()
              })
              .catch(error => {
                console.error(error)
                setData(oldData => {
                  return {
                    ...oldData,
                    hasPurchase: false,
                    preLicenseActivationFailed: true,
                    preLicenseActivationFinished: false,
                    purchaseStateReady: true
                  }
                })
              })
          } else {
            setData(oldData => {
              return {
                ...oldData,
                purchaseStateReady: true,
                hasPurchase: false
              }
            })
          }
        }
      }
    )
  }

  const onPurchaseComplete = (isStripe, optionalPaymentId) => {
    if (isStripe) {
      onPostPurchaseAdd()
      return
    }
    if (typeof optionalPaymentId === 'object') {
      optionalPaymentId = null
    }
    trackCustomEvent({
      category: 'ecommerce',
      action: 'purchase_complete',
      value: optionalPaymentId
    })
    console.log(`onPurchaseComplete ${optionalPaymentId}`)
    API.addPurchase(
      {
        lang: locale,
        paymentId: optionalPaymentId
      },
      auth.currentUser,
      loadingCallback
    ).then(response => {
      onPostPurchaseAdd(optionalPaymentId)
    })
  }

  const onPostPurchaseAdd = optionalPaymentId => {
    trackCustomEvent({
      category: 'ecommerce',
      action: 'buy_panel',
      value: optionalPaymentId
    })
    Linker.setUrl(localizedUrl('/purchase', locale))
  }

  const onPurchaseError = (paymentId, payerId) => {
    console.error('Purchase failed')
    setError({
      paymentId: paymentId,
      payerId: payerId
    })
  }

  const onPurchaseCancel = () => {
    console.log('Purchase canceled')
  }

  const onDisconnectSerial = () => {
    console.log('disconnect serial')
    API.deactivateSerial(
      {
        adobeSerial: data.serial
      },
      auth.currentUser,
      loadingCallback
    )
      .then(result => {
        console.log(result, 'disconnected ', data.serial)
        setData(oldData => {
          return {
            ...oldData,
            serial: result.serial
          }
        })
      })
      .catch(error => {
        console.error(error)
      })
  }

  const onDownload22 = () => {
    Linker.openUrl('/download', '_blank')
  }

  const onDownload30 = () => {
    Linker.openUrl('/download3', '_blank')
  }

  useEffect(componentDidMount, []) // componentDidMount
  if (!authSetup && auth) {
    auth.onAuthStateChanged(onAuthStateChanged)
    setAuthSetup(true)
  }

  return (
    <PayPalScriptProvider
      options={{
        currency: 'EUR',
        'client-id': config.settings.paypal.getClientId(),
        debug: config.settings.paypal.sandbox
      }}
    >
      <EulaModalContext.Provider
        value={{
          setIsEulaOpen: setIsEulaOpen
        }}
      >
        <Nav defaultIndex={2} logout />
        <PurchaseError error={error} />
        <Loading loading={loading} />
        {processingPurchase ? (
          <Fragment>
            <FormattedMessage id='purchase.processing' />
            <Spinner name='line-scale' />
          </Fragment>
        ) : (
          <Fragment>
            {auth && (
              <FullscreenWrapper>
                <Content>
                  {auth.currentUser && data.purchaseStateReady ? (
                    <Fragment>
                      <Verification auth={auth} authModule={authModule}/>

                      {auth.currentUser.emailVerified && (
                        <PurchaseState
                          auth={auth}
                          data={data}
                          onDisconnectSerial={onDisconnectSerial}
                          onDownload22={onDownload22}
                          onDownload30={onDownload30}
                          onPurchaseComplete={onPurchaseComplete}
                          onPurchaseError={onPurchaseError}
                        />
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <FormattedMessage id='purchase.only_members' />
                    </Fragment>
                  )}

                  <Eula isOpen={isEulaOpen} setIsOpen={setIsEulaOpen} />
                </Content>
              </FullscreenWrapper>
            )}
          </Fragment>
        )}
      </EulaModalContext.Provider>
    </PayPalScriptProvider>
  )
})
