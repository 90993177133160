import React, { Fragment, useState, useEffect } from 'react'
import API from '@src/common/api'
import { SmallBlueButton } from '@components/buttons/style'
import { FormattedMessage, injectIntl } from 'react-intl'
import { PS } from '@src/style/core'

import {
  FUNDING,
  usePayPalScriptReducer,
  PayPalButtons
} from '@paypal/react-paypal-js'

export default ({ onPurchaseComplete, onPurchaseFailed }) => {
  const [loading, setLoading] = useState(false)
  const [apiData, setApiData] = useState({
    apiReady: false
  })
  const [{ isPending }] = usePayPalScriptReducer()

  const loadingCallback = ({ loading }) => {
    setLoading(loading)
  }

  const componentDidMount = () => {
    const urlCode = API.getParameterByName('code')
    API.pricing(urlCode, loadingCallback)
      .then(response => {
        const result = {
          apiReady: true,
          loading: false,
          fullPrice: response.fullPrice.price,
          code: urlCode
        }
        if (response.discountPrice) {
          result.discountPrice = response.discountPrice.price
          result.codeInactive = response.codeInactive
        }
        setApiData(result)
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(componentDidMount, []) // componentDidMount

  return (
    <Fragment>
      {loading || !apiData.apiReady ? (
        <Fragment />
      ) : (
        <Fragment>
          {apiData.code && (
            <PS>
              <FormattedMessage
                id='buy_buttons.discount.active'
                values={{
                  inactive: msg => {
                    if (apiData.discountPrice === undefined) {
                      return (
                        <FormattedMessage id='buy_buttons.discount.inactive' />
                      )
                    }
                  },
                  strong: msg => <strong>{msg}</strong>,
                  code: apiData.code
                }}
              />
            </PS>
          )}
          {apiData.discountPrice === 0 ? (
            <Fragment>
              <FormattedMessage id='buy_buttons.get_your_copy_free' />
              <SmallBlueButton onClick={onPurchaseComplete}>
                <FormattedMessage id='buy_buttons.get_panel' />
              </SmallBlueButton>
            </Fragment>
          ) : (
            <>
              {isPending ? <div>Loading...</div> : null}
              <PayPalButtons
                fundingSource={FUNDING.PAYPAL}
                style={{
                  layout: 'horizontal',
                  shape: 'pill',
                  color: 'blue'
                }}
                createOrder={(data, actions) => {
                  console.log('createOrder', apiData)
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: apiData.discountPrice || apiData.fullPrice
                        }
                      }
                    ]
                  })
                }}
                onApprove={async (data, actions) => {
                  console.log('onApprove', data)
                  const details = await actions.order.capture()
                  const isStripe = false
                  console.log('onApprove', 'details', details)
                  onPurchaseComplete(isStripe)
                }}
                onError={error => {
                  console.log('onError', 'error', error)
                  onPurchaseFailed(error, '')
                }}
              />
            </>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}
