import styled, { css } from "styled-components"
import * as GlobalStyle from "@src/style/globalStyle"
import { device, core } from "@src/style"
import React from "react"

export const Header = styled.div``

export const Close = styled.a`
  position: absolute;
  right: 24px;
  top: 18px;
  width: 16px;
  height: 16px;
  z-index: 999;
  color: #dbdbdbaa;

  &:hover {
    cursor: pointer;
    color: #dbdbdb;
  }
`