import React, { useState, useEffect } from 'react'
import API from '@src/common/api'
import config from '@src/common/config'
import { SmallBlueButton, BlueButton } from '@components/buttons/style'
import { FormattedMessage, injectIntl } from 'react-intl'
import { PS } from '@src/style/core'
import StripeWidget from '@components/buyButtons/stripeWidget'
import PaypalWidget from '@components/buyButtons/paypalWidget'

import Linker from '@src/common/linker'

export default ({ auth, onPurchaseComplete, onPurchaseError }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    apiReady: false
  })

  const loadingCallback = ({ loading }) => {
    setLoading(loading)
  }

  const componentDidMount = () => {
    const urlCode = API.getParameterByName('code')
    API.pricing(urlCode, loadingCallback)
      .then(response => {
        const result = {
          apiReady: true,
          loading: false,
          fullPrice: response.fullPrice.price,
          code: urlCode,
          totalPrice: response.discountPrice
            ? response.discountPrice.price
            : response.fullPrice.price
        }
        if (response.discountPrice) {
          result.discountPrice = response.discountPrice.price
          result.codeInactive = response.codeInactive
        }
        setData(result)
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(componentDidMount, []) // componentDidMount

  return (
    <>
      {loading || !data.apiReady ? (
        <>
          <FormattedMessage id='buy_buttons.loading_methods' />
        </>
      ) : (
        <>
          {data.discountPrice === 0 ? (
            <>
              <FormattedMessage id='buy_buttons.get_your_copy_free' />
              <BlueButton
                onClick={() => {
                  onPurchaseComplete(false)
                }}
              >
                <FormattedMessage id='buy_buttons.get_panel' />
              </BlueButton>
            </>
          ) : (
            <>
              <Price {...data} />
              <br />
              <StripeWidget auth={auth} />
              <br />
              <br />
              <FormattedMessage id='buy_buttons.paypal_alternative' />
              <br />
              {/* <PayPalPlus
                sandbox={config.paypalSandbox}
                amount={data.totalPrice}
                currency="EUR"
                returnUrl={Linker.currentUrl()}
              /> */}
              <PaypalWidget
                onPurchaseComplete={onPurchaseComplete}
                onPurchaseError={onPurchaseError}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

const getDiscountPercentage = (discountPrice, fullPrice) => {
  return Math.abs(Math.round((1 - discountPrice / fullPrice) * 100))
}

const Price = ({ code, discountPrice, fullPrice }) => {
  if (discountPrice && discountPrice < fullPrice) {
    return (
      <div>
        {code && (
          <PS>
            <FormattedMessage
              id='buy_buttons.discount.active'
              values={{
                inactive: msg => {
                  if (discountPrice === undefined) {
                    return (
                      <FormattedMessage id='buy_buttons.discount.inactive' />
                    )
                  }
                  return <></>
                },
                strong: msg => <strong>{msg}</strong>,
                code: code
              }}
            />
          </PS>
        )}
        <FormattedMessage
          id='buy_buttons.price_with_discount'
          values={{
            discountPrice: discountPrice
          }}
        />
        <br />
        <span style={{ fontSize: '0.8rem' }}>
          <FormattedMessage id='buy_buttons.original_price' />
          <span
            style={{
              opacity: '0.8'
            }}
          >
            <FormattedMessage
              id='buy_buttons.discount.fullprice'
              values={{
                fullPrice: fullPrice
              }}
            />
          </span>
          <FormattedMessage
            id='buy_buttons.discount.discount'
            values={{
              discount: getDiscountPercentage(discountPrice, fullPrice)
            }}
          />
        </span>
      </div>
    )
  } else {
    return (
      <div>
        <FormattedMessage
          id='buy_buttons.price'
          values={{
            price: fullPrice
          }}
        />
      </div>
    )
  }
}
