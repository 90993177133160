import React, { Fragment } from "react"

import * as Styles from "./styles"
import * as GlobalStyle from "@src/style/globalStyle"
import { FormattedMessage, injectIntl } from "react-intl"
import { LocalizedLink } from "@src/components/link"
import { StyledMessages, StyledMessage } from "@components/common/styledMessage"
import PurchaseSection from "@components/purchase/purchaseSection"

import { EulaModalContext } from "./eula/context.js"

export default injectIntl(
  ({
    intl,
    auth,
    data,
    onPurchaseComplete,
    onPurchaseError,
    onDisconnectSerial,
    onDownload22,
    onDownload30,
  }) => {
    const extraValues = {
      licensecode3: (...msg) => (
        <>
          {!data.v3Codes && <div>No ARCPanel 3.0 codes yet</div>}
          {data.v3Codes && (
            <ul>
              {data.v3Codes.map((code, index) => {
                return <li key={index}>{code}</li>
              })}
            </ul>
          )}
        </>
      ),
      disconnect: (msg) => (
        <Styles.BlueButton hollow onClick={onDisconnectSerial}>
          {msg}
        </Styles.BlueButton>
      ),
      download22: (msg) => (
        <Styles.BlueButton onClick={onDownload22}>{msg}</Styles.BlueButton>
      ),
      download30: (msg) => {
        return (
          <Styles.BlueButton onClick={onDownload30}>{msg}</Styles.BlueButton>
        )
      },
      instructions: (msg) => (
        <Styles.PM>
          <LocalizedLink to="blog/arcpanel/instructions/2019-03-30-installation-instructions">
            {msg}
          </LocalizedLink>
        </Styles.PM>
      ),
      activation: (msg) => (
        <Styles.PM>
          <LocalizedLink to="blog/arcpanel/instructions/2019-03-30-installation-instructions#panel_activation">
            {msg}
          </LocalizedLink>
        </Styles.PM>
      ),
      purchasing: (msg) => (
        <PurchaseSection auth={auth} onPurchaseComplete={onPurchaseComplete} onPurchaseError={onPurchaseError} />
      ),
      licenseCode: data.code,
      serial: `${data.serial}`,
    }

    return (
      <GlobalStyle.Column>
        <Styles.H2>
          <FormattedMessage
            id="purchase.greetings.welcome"
            values={{
              username: auth.currentUser.displayName,
            }}
          />
        </Styles.H2>
        <Styles.PM>
          <StyledMessage
            id="purchase.greetings.email_verified"
            values={{
              email: auth.currentUser.email,
            }}
          />
        </Styles.PM>
        {data.hasPurchase ? (
          <>
            <Styles.H2>ARCPanel Pro</Styles.H2>
            <StyledMessages
              id="purchase.greetings.hasPurchase.v3"
              values={extraValues}
            />
            <Styles.H3>ARCPanel 2.2</Styles.H3>
            <StyledMessages
              id="purchase.greetings.hasPurchase"
              values={extraValues}
            />
          </>
        ) : (
          <Fragment>
            <StyledMessages
              id="purchase.greetings.no_purchase"
              values={extraValues}
            />
          </Fragment>
        )}
        <Styles.PS>
          <EulaModalContext.Consumer>
            {({ setIsEulaOpen }) => {
              return (
                <StyledMessage
                  id="buy_buttons.eula"
                  values={{
                    eula: (...msg) => (
                      <a
                        href="#"
                        onClick={() => {
                          setIsEulaOpen(true)
                        }}
                      >
                        {msg}
                      </a>
                    ),
                  }}
                />
              )
            }}
          </EulaModalContext.Consumer>
        </Styles.PS>
      </GlobalStyle.Column>
    )
  }
)
