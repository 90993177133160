import React, { useState } from 'react'

import { VerificationBody, H2, H3, PM, VerifyBlueButton } from './styles'
import { FormattedMessage } from 'react-intl'

export default ({ auth, authModule }) => {
  const [verificationSent, setVerificationSent] = useState(false)

  const onVerifyClicked = () => {
    try {
      auth.currentUser
        .sendEmailVerification()
        .then(e => {
          console.log('Verification email sent successfully')
          setVerificationSent(true)
        })
        .catch(error => {
          console.log('Verification email not sent')
          console.log(error)
        })
    } catch (e) {
      authModule
        .sendEmailVerification(auth.currentUser)
        .then(e => {
          console.log('Verification email sent successfully')
          setVerificationSent(true)
        })
        .catch(error => {
          console.log('Verification email not sent')
          console.log(error)
        })
    }
  }

  return (
    <div>
      {!auth.currentUser.emailVerified && (
        <VerificationBody>
          <H2>
            <FormattedMessage id='purchase.verification.verify_email' />
          </H2>
          {verificationSent ? (
            <>
              <PM>
                <FormattedMessage id='purchase.verification.sent.text1' />
              </PM>
              <PM>
                <FormattedMessage id='purchase.verification.sent.text2' />
              </PM>
            </>
          ) : (
            <>
              <PM>
                <FormattedMessage id='purchase.verification.notsent.text1' />
              </PM>
              <PM>
                <FormattedMessage id='purchase.verification.notsent.text2' />
              </PM>
            </>
          )}
          <VerifyBlueButton onClick={onVerifyClicked}>
            <FormattedMessage id='purchase.verification.verify' />
          </VerifyBlueButton>
        </VerificationBody>
      )}
    </div>
  )
}
