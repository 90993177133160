import React from 'react'
import styled from 'styled-components'

const Loading = styled.div`
  padding: 50vh;
  left: 0;
  top: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
`

const LoadingContents = styled.div`
  width: 15vw;
  margin: 0 auto;
  color: white;
`

export default ({ loading }) => {
  return (
    loading && (
      <Loading>
        <LoadingContents>Please wait... updating state</LoadingContents>
      </Loading>
    )
  )
}
