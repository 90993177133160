import React, { Fragment, useState, useEffect, useCallback } from "react"
import API from "@src/common/api"
import { MediumYellowButton } from "@components/buttons/style"
import { FormattedMessage, injectIntl } from "react-intl"
import { PS } from "@src/style/core"
import config from "@src/common/config"

import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { CardElement } from "@stripe/react-stripe-js"

const stripePromise = loadStripe(config.settings.stripe.getKey())

export default ({ auth }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const loadingCallback = useCallback(({ loading }) => {
    setLoading(loading)
  })

  const buyWithStripe = useCallback(async () => {
    const urlCode = API.getParameterByName("code")

    const stripe = await stripePromise
    const response = await API.createStripeSession(
      urlCode,
      auth.currentUser,
      loadingCallback
    )
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: response.id,
    })

    if (result.error) {
      setError(result.error.message)
    }
  })

  return (
    <>
      {error && <div>{error.message}</div>}
      {!error && (
        <>
          {loading && <div>Loading...</div>}
          {!loading && (
            <MediumYellowButton onClick={buyWithStripe}>
              <FormattedMessage id="buy_buttons.stripe.buy" />
            </MediumYellowButton>
          )}
          <FormattedMessage id="buy_buttons.stripe.options" />
        </>
      )}
    </>
  )
}
