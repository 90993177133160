import React from 'react'
import styled from 'styled-components'
import { LocalizedLink } from '@src/components/link'
import { FormattedMessage, injectIntl } from 'react-intl'

export default injectIntl(({ intl: { locale }, error }) => {
  return (
    <div>
      {error && (
        <div>
          <br />
          <FormattedMessage id='payment.error' />
          <br />
          PaymentId: {error.paymentId}
          <br />
          PayerId: {error.payerId}
          <br />
          <br />
          <LocalizedLink to='/purchase'>Back to Members area</LocalizedLink>
        </div>
      )}
    </div>
  )
})
